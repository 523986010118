.main_ctn{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex; flex-direction: column;
    font-family: 'Notosans';
}
.main_ctn .header_ctn{
    height: 60px; box-sizing: border-box;
    display: flex; align-items: center; justify-content: space-between;
    position: relative;
}

.header_center{
    position: absolute; left: 0; right: 0;
    box-sizing: border-box; pointer-events: none;
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
}
.header_center img {
    height: 24px; width: auto; justify-content: center;
    padding: 12px 6px 12px 0px;
}
.header_center span {
    display: flex; align-items: center; justify-content: center;
    font-weight: 500; font-size: 16px;
}
.header_side_ctn{
    height: 100%; width: auto;
    display: flex; align-items: center; justify-content: center;
}
.header_side_ctn img{
    height: 24px;
}
.header_side_ctn.left{
    padding-left: 5%;
    padding-right: 12px;
}
.header_side_ctn.right{
    padding-left: 12px;
    padding-right: 5%;
}
.body_ctn{
    flex: 1;
    display: flex; flex-direction: column;
    box-sizing: border-box;
}
.top_ctn{
    display: flex; flex-direction: row;
    box-sizing: border-box; padding: 12px; border-radius: 12px;
    border: 1px solid #FDCA56;font-size: 12px;
    align-items: center; justify-content: space-around;
    margin-bottom: 12px;
}
.top_ctn img{
    height: 45px;
}
.top_ctn div{
    display: flex; flex-direction: column;
    align-items: center;
}
.bot_ctn{
    display: flex; flex-direction: column;
    box-sizing: border-box; padding: 12px; border-radius: 12px;
    background-color: #F8F8F8;
}