.main_ctn{
    height: 100%;
    width: 100%;
    display: flex; flex-direction: column;
    font-family: 'Notosans';
}
.main_ctn .header_ctn{
    height: 60px; box-sizing: border-box;
    display: flex; align-items: center; justify-content: space-between;
    position: relative;
}

.header_center{
    position: absolute; left: 0; right: 0;
    box-sizing: border-box; pointer-events: none;
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
}
.header_side_ctn{
    height: 100%; width: auto;
    display: flex; align-items: center; justify-content: center;
}
.header_side_ctn img{
    height: 24px;
}
.header_side_ctn.left{
    padding-left: 5%;
    padding-right: 12px;
}
.header_side_ctn.right{
    padding-left: 12px;
    padding-right: 5%;
}
.header_center img {
    height: 24px; width: auto; justify-content: center;
    padding: 12px 6px 12px 0px;
}
.header_center span {
    display: flex; align-items: center; justify-content: center;
    font-weight: 500; font-size: 16px;
}
.main_ctn .body_ctn{
    flex: 1;
    display: flex; flex-direction: column;
    box-sizing: border-box;
    font-family: 'Notosans'; overflow-y: auto;
}
.btn_add_item_ctn{
    display: flex; justify-content: flex-end;
    padding: 8px 0px; align-items: center;
}
.deduct_ctn{
    margin-top: 16px;
    box-shadow: 0px 0px 3px 2px lightgray; box-sizing: border-box;
    display: flex; flex-direction: column;
    padding: 18px; border-radius: 18px;
}
.amount_comp_ctn{
    margin-top: 12px;
    box-sizing: border-box; border: 3px solid #FDCA56;
    padding: 4px 18px 4px 18px; border-radius: 18px;
    display: flex; flex-direction: column;
}
.btn_pre_paystub_dialog{
    box-sizing: border-box; margin-bottom: 12px;
    padding: 8px 24px 8px 24px; border-radius: 52px;
    border: 1px solid #818181;
    display: flex; justify-content: center; align-items: center;
    color: #818181;
}
.btn_submit{
    box-sizing: border-box;
    width: 100%; padding: 12px; border-radius: 52px;
    display: flex; justify-content: center;
}
.foo_ctn{
    display: flex; flex-direction: column;
    align-items: center;
    padding: 24px 2.5% 24px 2.5%;
    box-sizing: border-box; font-family: 'Notosans';
}
.header_side_ctn {
    height: 100%; width: auto;
    padding: 12px 12px 12px 0px; box-sizing: border-box;
    display: flex; align-items: center; justify-content: center;
}
/* ::-webkit-scrollbar {
  display: -webkit-box;
} */