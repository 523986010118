
.hourly_wage_input_ctn{
    display: flex; flex-direction: row;
    box-sizing: border-box; padding: 8px 0px; align-items: center;
}
.hourly_wage_input_ctn span {
    word-break: keep-all; -ms-word-break: keep-all;
    white-space: nowrap; font-size: 14px;
}
.hourly_wage_input_ctn input {
    width: 0px; flex: 1; height: 24px;
    margin-inline-start: 12px; margin-inline-end: 4px;
    border: 1px solid #BFBFBF; border-radius: 4px;
    outline-width: 0px; text-align: end;
}