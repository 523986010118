
.main_ctn{
    height: 100%;
    width: 100%;
    display: flex; flex-direction: column;
    font-family: 'Notosans';
}

.main_ctn .header_ctn{
    height: 60px; box-sizing: border-box;
    display: flex; align-items: center; justify-content: space-between;
    position: relative;
}
.header_center{
    height: 100%;
    position: absolute; left: 0; right: 0;
    box-sizing: border-box; pointer-events: none;
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
}
.header_center img {
    height: 60%; width: auto; justify-content: center;
}
.header_center span {
    display: flex; align-items: center; justify-content: center;
    font-weight: 500; font-size: 16px;
}
.header_side_ctn{
    height: 100%; width: auto;
    display: flex; align-items: center; justify-content: center;
}
.header_side_ctn img{
    height: 24px;
}
.header_side_ctn.left{
    padding-left: 5%;
    padding-right: 12px;
}
.header_side_ctn.right{
    padding-left: 12px;
    padding-right: 5%;
}
.main_ctn .body_ctn{
    flex: 1;
    display: flex; flex-direction: column;
    box-sizing: border-box;
    font-family: 'Notosans'; overflow-y: auto;
}

.deduct_ctn{
    margin-bottom: 18px;
    box-sizing: border-box;
    border : 1px solid #CCCCCC;
    display: flex; flex-direction: column;
    padding: 6px 18px; border-radius: 18px;
}
.amount_comp_ctn{
    margin-bottom: 18px;
    box-sizing: border-box; border: 3px solid #FDCA56;
    padding: 4px 18px 4px 18px; border-radius: 18px;
    display: flex; flex-direction: column;
}