.main_ctn{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex; flex-direction: column;
    font-family: 'Notosans';
}
.main_ctn .header_ctn{
    min-height: 60px; box-sizing: border-box; 
    display: flex; align-items: center; justify-content: space-between;
    position: relative;
}

.header_center{
    position: absolute; left: 0; right: 0;
    box-sizing: border-box; pointer-events: none;
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
}
.header_side_ctn{
    height: 100%; width: auto;
    display: flex; align-items: center; justify-content: center;
}
.header_side_ctn img{
    height: 24px;
}
.header_side_ctn.left{
    padding-left: 2.5%;
    padding-right: 12px;
}
.header_side_ctn.right{
    padding-left: 12px;
    padding-right: 2.5%;
}
.header_center img {
    height: 24px; width: auto; justify-content: center;
    padding: 12px 6px 12px 0px;
}
.header_center span {
    display: flex; align-items: center; justify-content: center;
    font-weight: 500; font-size: 16px;
}
.main_ctn .body_ctn{
    flex: 1;
    display: flex; flex-direction: column;
    box-sizing: border-box;
    font-family: 'Notosans'; overflow-y: auto;
}
.write_ctn{
    display: flex; flex-direction: row; align-items: center;
    padding: 6px 8px; box-sizing: border-box;
    background-color: #FDCA56; border-radius: 4px;
}

.write_ctn span{
    font-size: 14px; font-weight: 500; color: #FFFFFF;
}