
.ctn{
    display: flex; flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box; padding: 8px 0px; align-items: center;
}

.ctn span.title {
    word-break: keep-all; -ms-word-break: keep-all;
    white-space: nowrap; font-size: 14px; font-weight: 500;
}
.ctn div.date_picker {
    display: flex; flex-shrink: 1;
    align-items: center; justify-content: space-between;
    box-sizing: border-box;
    padding: 4px 12px;
    min-width: 0px; height: 36px;
    border: 1px solid #BFBFBF; border-radius: 6px;
    position:relative;
}
.date_picker span.date {
    font-weight: 500; font-size: 14px;
}
.date_picker img.icon {
    margin-left: 8px;
    height: 80%;
}