.btn_main{
    border-radius: 9px; 
    /* width:40%; aspect-ratio: 1/1; */
    width: 120px; 
    height: 120px;
    /* max-width: 180px; */
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    font-weight: 500;
    box-sizing: border-box;
    background-color: white;
    color: #707070;
    box-shadow: 0px 0px 8px #D9D9D9;
  }
.btn_main:hover, .btn_main:active{
    box-sizing: border-box;
    background-color: #FDCA56;
    color: white;
    box-shadow: -2px -2px 4px #FFFFFF inset;
}
.mid_hori_line {
    margin: 24px 0px;
    border-top: 1px solid rgba(183, 183, 183, 0.34);
}
.background_img{
    width: 100vw; height: 100vh;
    position: absolute;
    top: 0; left: 0; pointer-events: none;
}
.main_ctn{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex; flex-direction: column;
    position: relative;
    font-family: 'Notosans';
}

.main_ctn .header_ctn{
    height: 60px; box-sizing: border-box;
    display: flex; align-items: center; justify-content: space-between;
    position: relative;
}
.header_center{
    height: 100%;
    position: absolute; left: 0; right: 0;
    box-sizing: border-box; pointer-events: none;
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
}
.header_center img {
    height: 60%; width: auto; justify-content: center;
}
.header_center span {
    pointer-events: auto;
    font-weight: 500; font-size: 16px; padding:12px
}
.header_side_ctn{
    height: 100%; width: auto;
    display: flex; align-items: center; justify-content: center;
}
.header_side_ctn img{
    height: 24px;
}
.header_side_ctn.left{
    padding-left: 5%;
    padding-right: 12px;
}
.header_side_ctn.right{
    padding-left: 12px;
    padding-right: 5%;
}
.body_ctn{
    flex: 1; flex-direction: column;
    display: flex;box-sizing: border-box;
    overflow-y: scroll;
}
.top_ctn{
    border-radius: 12px; background-color: #F8F8F8;
    display: flex; flex-direction: column;
    padding: 18px; font-size: 14px;
}
.foo_ctn{
    width: 100%;
    padding: 0px 5% 0px 5%; box-sizing: border-box;
    background-color: rgba(103, 81, 56, 0.32);
}
.foo_ctn .foo_p{
    font-family:Notosans;
    font-size: 12px; color:white;
    font-weight: 500;
}
.foo_ctn .foo_p .foo_span{
    font-size: 10px;
    font-weight: normal;
}
.tab_container{
    margin-top: 30px;
    display: flex; flex-direction: column;

    word-break: keep-all; -ms-word-break: keep-all;
    white-space: nowrap;
}
.tab_title_ctn{
    display: flex; align-items: center;
    margin-bottom: 8px; margin-left: 36px; margin-right: 36px;
}
.tab_title_ctn span{
    /* fontWeight: "bold", fontSize: "20px", */
    font-weight: bold; font-size: 18px;
}
.tab_title_icon{
    width: 45px; height: auto;
}
.tab_item_ctn{
    background-color: #FDCA56;
    display: flex; align-items: center;
    padding:8px 0px 8px 36px;margin-right: 18px;
    border-radius: 0px 30px 30px 0px;
}
.tab_item_icon{
    width: 40px; height: auto;
}
.tab_item_ctn span{
    font-weight: 500;
    font-size: 16px; margin-left: 8px; 
}