.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*{
  -ms-overflow-style: none;
}

.pointer-cursor {
  cursor: pointer;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.parent-div {
  width: 100vw;
  height: 100vh;
}
.parent-div-per{
  box-sizing: border-box;
  /* border:1px solid orangered; */
  flex:1
}
.name_deco{
  font-size: 22px; font-weight: bold;
  text-underline-offset: -30%;
  text-underline-position: below;
  -ms-text-underline-position: under;
  text-decoration-line: underline; -moz-text-decoration-line: underline;
  text-decoration-color: #FDCA56; -moz-text-decoration-color: #FDCA56;
  text-decoration-thickness: 40%;
}
.side_margin_5per{
  margin-left: 5%; margin-right: 5%;
}
.side_margin_2_5per{
  margin-left: 2.5%; margin-right: 2.5%;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'NotoSans';
  src: local('NotoSans'), url('./fonts/NotoSansCJKkr-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-weight: normal;
  src: local('NotoSans'), url('./fonts/NotoSansCJKkr-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-weight: bold;
  src: local('NotoSans'), url('./fonts/NotoSansCJKkr-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-weight: '900';
  src: local('NotoSans'), url('./fonts/NotoSansCJKkr-Black.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-weight: lighter;
  src: local('NotoSans'), url('./fonts/NotoSansCJKkr-Light.otf') format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-weight: 500;
  src: local('NotoSans'), url('./fonts/NotoSansCJKkr-Medium.otf') format('opentype');
}
.ql-container{
  overflow-y: auto;
}