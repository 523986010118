.drawer_ctn{
    background-color: transparent;
    width: 100vw; height: 100vh;
    position: absolute; left: 0; top: 0;
}
.background_ctn{
    width: 100%; height: 100%;
    background-color: rgba(52, 52, 52, 0.8);
    display: flex; flex-direction: column;
}
.body_ctn{
    width: 80%; max-width: 800px;
    height: 100%;
    background-color: white;
    display: flex; flex-direction: column;
    box-sizing: border-box; padding: 5%;
    position:relative;
    font-family: 'Notosans';
}
.top_btn_ctn{
    display: flex; justify-content: space-between;
}
.top_side_icon{
    height: 27px; width: auto;
}
div.top_user_id{
    font-family: 'Notosans';
    box-sizing: border-box; padding-top: 12px; padding-bottom: 12px;
    border-bottom-width: 1px; border-bottom-style: solid; border-bottom-color: rgba(183, 183, 183, 0.38);
}
.tab_container{
    margin-top: 30px;
    display: flex; flex-direction: column;
}
.tab_title_ctn{
    display: flex; align-items: center;
    margin-bottom: 8px;
}
.tab_title_ctn span{
    /* fontWeight: "bold", fontSize: "20px", */
    font-weight: bold; font-size: 18px;
}
.tab_title_icon{
    width: 45px; height: auto;
}
.tab_item_ctn{
    display: flex; align-items: center;
    margin-left: 45px; margin-bottom: 12px;
}
.tab_item_icon{
    width: 22.5px; height: auto;
}
.tab_item_ctn span{
    font-size: 16px; margin-left: 8px; color: #767676;
}
.footer{
    position: absolute; left: 0; bottom: 0;
    width:70%; height: auto;
    pointer-events: none;
}