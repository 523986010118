.main_ctn{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex; flex-direction: row;
    font-family: 'Notosans';
}
.main_ctn .header_ctn{
    height: 60px; box-sizing: border-box;
    display: flex; align-items: center; justify-content: space-between;
    position: relative;
}

.header_center{
    position: absolute; left: 0; right: 0;
    box-sizing: border-box; pointer-events: none;
    display: flex; flex-direction: row;
    align-items: center; justify-content: center;
}
.header_center img {
    height: 24px; width: auto; justify-content: center;
    padding: 12px 6px 12px 0px;
}
.header_center span {
    display: flex; align-items: center; justify-content: center;
    font-weight: 500; font-size: 16px;
}
.header_side_ctn{
    height: 100%; width: auto;
    display: flex; align-items: center; justify-content: center;
}
.header_side_ctn img{
    height: 24px;
}
.header_side_ctn.left{
    padding-left: 5%;
    padding-right: 12px;
}
.header_side_ctn.right{
    padding-left: 12px;
    padding-right: 5%;
}
.header_cell{
    display: flex; align-items: center; justify-content: center;
    height: 100%; border-bottom: 1px solid #EAEAEA;
}
.body_ctn{
    flex: 1;
    display: flex; flex-direction: column;
    box-sizing: border-box;
}
.row_cell{
    font-size: 12px; font-family: 'Notosans'; font-weight: 500;
    word-break: keep-all; -ms-word-break: keep-all;
    white-space: nowrap;
    box-sizing: border-box; text-align: center;
}
.gray_under_line{
    /* border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #DBDBDB; */
}
.row_ctn{
    display: flex; flex-direction: row;
    align-items: center;
    padding: 8px 5%; box-sizing: border-box;
}